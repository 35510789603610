import request from "@utils/request";

export function getCoreSymbolRule() {
    return request({
        url: "oz/pk-core-symbol-rule",
        method: "get"
    });
}

export function updateCoreSymbolRule(data) {
    return request({
        url: "oz/pk-core-symbol-rule",
        method: "put",
        data
    });
}

export function getPriceChannelRule() {
    return request({
        url: "oz/pk-price-channel-rule",
        method: "get"
    });
}

export function updatePriceChannelRule(data) {
    return request({
        url: "oz/pk-price-channel-rule",
        method: "put",
        data
    });
}

export function getSymbolFeedConfig() {
    return request({
        url: "oz/pk-symbol-feed-config",
        method: "get"
    });
}

export function updateSymbolFeedConfig(data) {
    return request({
        url: "oz/pk-symbol-feed-config",
        method: "put",
        data
    });
}
