<template>
    <div>
        <v-row>
            <v-col cols="10">
                <v-tabs>
                    <v-tab
                        v-model="selectedTab"
                        v-for="tabName in tabNames"
                        :key="tabName"
                        :id="tabName"
                        v-on:click="changeTab(tabName)"
                        >{{ tabName }}</v-tab
                    >
                </v-tabs>
            </v-col>
            <v-col style="display: flex" cols="2">
                <v-row justify="end" align="center">
                    <v-btn
                        small
                        color="blue-grey"
                        class="mr-2 ml-2"
                        @click="forceReload"
                        :loading="loading"
                        ><v-icon>mdi-refresh</v-icon></v-btn
                    >
                    <v-btn
                        small
                        color="primary"
                        class="mr-3"
                        @click="preCreationProcess('Add', null)"
                        :loading="loading"
                    >
                        Add
                    </v-btn>
                </v-row>
            </v-col>
        </v-row>
        <v-data-table
            dense
            :headers="headerOptions[selectedTab]"
            :items="configData"
            item-key="id"
            ref="configTable"
            :loading="loading"
            :header-props="{ sortIcon: null }"
        >
            <template v-slot:item.user_id="{ item }">
                {{ optionIdToName("Users", item.user_id) }}
            </template>
            <template v-slot:item.group_id="{ item }">
                {{ optionIdToName("Groups", item.group_id) }}
            </template>
            <template v-slot:item.rule_id="{ item }">
                {{ optionIdToName("Rules", item.rule_id) }}
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon
                    class="mr-2"
                    @click="preCreationProcess('Update', item.id)"
                    :disabled="loading"
                >
                    mdi-pencil
                </v-icon>
                <v-icon
                    dense
                    :disabled="loading"
                    @click="
                        dialogRemove.visible = true;
                        removeData = [item];
                    "
                    >mdi-delete</v-icon
                >
            </template>
        </v-data-table>
        <v-dialog
            v-model="dialogRemove.visible"
            width="1300"
            :hide-overlay="true"
            :persistent="true"
        >
            <v-card class="pt-0">
                <v-app-bar
                    flat
                    color="rgba(0, 0, 0, 0)"
                    style="background: rgb(26, 57, 88)"
                >
                    <v-toolbar-title class="text-h6 white--text pl-0">{{
                        dialogRemove.title
                    }}</v-toolbar-title>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="white"
                        icon
                        @click="dialogRemove.visible = false"
                        ><v-icon>mdi-close</v-icon></v-btn
                    >
                </v-app-bar>

                <v-card-actions>
                    <v-row>
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                            <v-data-table
                                dense
                                :headers="deleteHeader"
                                :items="removeData"
                                item-key="id"
                                class="elevation-0"
                                :loading="loading"
                                :items-per-page="-1"
                                id="home-table"
                                :hide-default-footer="true"
                                fixed-header
                                :header-props="{ sortIcon: null }"
                            >
                                <template v-slot:item.user_id="{ item }">
                                    {{ optionIdToName("Users", item.user_id) }}
                                </template>
                                <template v-slot:item.group_id="{ item }">
                                    {{
                                        optionIdToName("Groups", item.group_id)
                                    }}
                                </template>
                                <template v-slot:item.rule_id="{ item }">
                                    {{ optionIdToName("Rules", item.rule_id) }}
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card-actions>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="dialogRemove.visible = false"
                        :disabled="loading"
                        >Cancel</v-btn
                    >
                    <v-btn
                        color="red darken-1"
                        text
                        @click="confirmDeletion()"
                        :loading="loading"
                        ><span>Confirm</span></v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="updateDialog.visible" width="700" persistent>
            <v-card :loading="loading">
                <v-app-bar
                    flat
                    color="rgba(0, 0, 0, 0)"
                    style="background: rgb(26, 57, 88)"
                >
                    <v-toolbar-title class="text-h6 white--text pl-0">
                        {{ updateDialog.action }} Configuration
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn color="white" icon @click="onCancel"
                        ><v-icon>mdi-close</v-icon></v-btn
                    >
                </v-app-bar>

                <v-form ref="configForm">
                    <v-container style="margin-top: 16px">
                        <v-row v-if="updateDialog.action === 'Update'" key="id">
                            <v-col cols="4" class="pt-0 pb-0">
                                <label
                                    style="
                                        display: flex;
                                        align-items: center;
                                        height: 100%;
                                    "
                                >
                                    ID
                                </label>
                            </v-col>
                            <v-col cols="8" class="pt-0 pb-0">
                                <v-text-field
                                    v-model="tempData['id']"
                                    disabled
                                    hide-details
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row v-for="data in addHeader" :key="data.text">
                            <v-col cols="4" class="pt-0 pb-0">
                                <label
                                    style="
                                        display: flex;
                                        align-items: center;
                                        height: 100%;
                                    "
                                >
                                    {{ data.text }}
                                </label>
                            </v-col>
                            <v-col cols="8" class="pt-0 pb-0">
                                <v-switch
                                    v-if="data.value == 'enable'"
                                    v-model="tempData[data.value]"
                                    hide-details
                                    style="margin-top: 13px"
                                ></v-switch>
                                <v-autocomplete
                                    v-else-if="data.value == 'user_name'"
                                    v-model="tempData[data.value]"
                                    :items="siteUserOptions"
                                    item-text="name"
                                    item-value="name"
                                    required
                                    small-chips
                                    hide-details
                                    single-line
                                />
                                <v-autocomplete
                                    v-else-if="data.value == 'user_id'"
                                    v-model="tempData[data.value]"
                                    :items="optionData['Users']"
                                    item-text="user_name"
                                    item-value="id"
                                    chips
                                    required
                                    small-chips
                                    single-line
                                />
                                <v-autocomplete
                                    v-else-if="data.value == 'group_id'"
                                    v-model="tempData[data.value]"
                                    :items="optionData['Groups']"
                                    item-text="group_name"
                                    item-value="id"
                                    chips
                                    required
                                    small-chips
                                    single-line
                                />
                                <v-autocomplete
                                    v-else-if="data.value == 'rule_id'"
                                    v-model="tempData[data.value]"
                                    :items="optionData['Rules']"
                                    item-text="rule_name"
                                    item-value="id"
                                    chips
                                    required
                                    small-chips
                                    single-line
                                />
                                <v-text-field
                                    v-else
                                    v-model="tempData[data.value]"
                                    :readonly="data.text == 'Id'"
                                    :rules="
                                        data.value === 'email'
                                            ? emailRules
                                            : data.value === 'sms'
                                            ? phoneRules
                                            : []
                                    "
                                    required
                                    hide-details="auto"
                                    :placeholder="
                                        'Please enter a new value for ' +
                                        data.text
                                    "
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="onCancel">
                        Cancel
                    </v-btn>
                    <v-btn
                        color="primary"
                        text
                        @click="submit"
                        :disabled="readyToUpload"
                    >
                        Submit
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import Breadcrumbs from "@components/Breadcrumbs";
import { mapState, mapActions } from "vuex";
import { snackbar } from "@components/mixins/snackbar";
import { permission } from "@components/mixins/permission";
import deepClone from "deep-clone";
import { getOptions } from "@services/alert/config-options";

export default {
    mixins: [permission, snackbar],
    components: {
        Breadcrumbs,
    },
    data() {
        return {
            emailRules:  [
                v => !!v || "E-mail is required",
                v => /.+@.+\..+/.test(v) || "E-mail must be valid"
            ],
            phoneRules: [
                v => !!v || "Phone number is required",
                v => /^\+61\s4\d{8}$/.test(v) || "Please match format with +61 4xxxxxxxx"
            ],
            dialogRemove: {
                title: "Are you sure you want to remove this record?",
                visible: false,
            },
            updateDialog: {
                action: "Add",
                visible: false,
            },
            tabNames: [
                "Users",
                "Groups",
                "Rules",
                "User-Groups",
                "Group-Rules",
            ],
            selectedTab: "",
            addition: [],
            edition: [],
            removeData: [],
            tempData: {},
            siteUserOptions: [],
        };
    },
    computed: {
        ...mapState("Notification", [
            "configData",
            "updated",
            "loading",
            "headerOptions",
            "optionData",
        ]),
        addHeader() {
            if (this.headerOptions[this.selectedTab]) {
                let filtered = deepClone(
                    this.headerOptions[this.selectedTab]
                )?.filter(
                    (item) =>
                        item.value !== "id" &&
                        item.value !== "updated_at" &&
                        item.value !== "created_at" &&
                        item.value !== "actions"
                );
                return filtered;
            } else {
                return [];
            }
        },
        deleteHeader() {
            if (this.headerOptions[this.selectedTab]) {
                let filtered = deepClone(
                    this.headerOptions[this.selectedTab]
                ).filter((item) => item.value !== "actions");
                return filtered;
            }
        },
        readyToUpload() {
            const fields = this.addHeader.map((item) => item.value);
            let allExist = true;

            for (let i = 0; i < fields.length; i++) {
                if (fields[i] === "enable") {
                    continue;
                }
                if (!this.tempData[fields[i]]) {
                    allExist = false;
                    break;
                }
            }
            return !allExist;
        },
    },
    watch: {
        updated(nv) {
            if (nv) {
                if (!!this.$refs.configForm) {
                    this.$refs.configForm.reset();
                }
                this.updateDialog.visible = false;
                this.dialogRemove.visible = false;
                this.getConfigData(this.selectedTab);
            }
        },
    },
    methods: {
        ...mapActions("Notification", [
            "getNotificationDataAction",
            "deleteNotificationDataAction",
            "initialAction",
            "addNotificationDataAction",
            "updateNotificationDataAction",
        ]),
        changeTab(name) {
            this.selectedTab = name;
            this.getConfigData(name);
        },
        getConfigData(tabName) {
            this.getNotificationDataAction(tabName);
        },
        forceReload() {
            this.getConfigData(this.selectedTab);
        },
        confirmDeletion() {
            this.deleteNotificationDataAction({
                id: this.removeData[0].id,
                type: this.selectedTab,
            });
        },
        preCreationProcess(action, id) {
            this.updateDialog.action = action;
            this.updateDialog.visible = true;
            this.tempData = {};
            if (action === "Add") {
                const fields = this.addHeader.map((item) => item.value);
                if (fields.includes("enable")) {
                    this.tempData["enable"] = false;
                }
            } else if (action === "Update") {
                const fields = this.addHeader.map((item) => item.value);
                const copy = { id: id };
                fields.map((field) => {
                    copy[field] = this.configData.find(
                        (data) => data.id === id
                    )[field];
                });
                this.tempData = copy;
            } else {
                return;
            }
        },
        onCancel() {
            this.$refs.configForm.reset();
            this.updateDialog.visible = false;
        },
        submit() {
            if (this.updateDialog.action === "Add") {
                const params = { ...this.tempData };
                delete params.id;
                params["type"] = this.selectedTab;
                this.addNotificationDataAction(params);
            } else {
                const params = { ...this.tempData };
                params["type"] = this.selectedTab;
                this.updateNotificationDataAction(params);
            }
        },
        optionIdToName(type, id) {
            switch (type) {
                case "Users":
                    return !!this.optionData["Users"].length
                        ? this.optionData["Users"].find((option) => {
                              return option.id === id;
                          })?.user_name
                        : "";
                case "Groups":
                    return !!this.optionData["Groups"].length
                        ? this.optionData["Groups"].find((option) => {
                              return option.id === id;
                          })?.group_name
                        : "";
                case "Rules":
                    return !!this.optionData["Rules"].length
                        ? this.optionData["Rules"].find((option) => {
                              return option.id === id;
                          })?.rule_name
                        : "";
                default:
                    return "";
            }
        },
    },
    mounted() {
        this.initialAction().then(() => {
            this.changeTab(this.tabNames[0]);
        });
        getOptions("user").then((res) => {
            if (!!res.data) {
                this.siteUserOptions = res.data;
            }
        });
    },
};
</script>
