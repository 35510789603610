var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"fullscreen":_vm.dialog.fullscreen,"width":"700"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-3",attrs:{"color":"primary","small":"","disabled":_vm.isDisabled},on:{"click":_vm.getServerOptions}},on),[_vm._v(" ADD ")])]}}]),model:{value:(_vm.dialog.visible),callback:function ($$v) {_vm.$set(_vm.dialog, "visible", $$v)},expression:"dialog.visible"}},[_c('v-card',[_c('v-app-bar',{staticStyle:{"background":"rgb(26, 57, 88)"},attrs:{"flat":"","color":"rgba(0, 0, 0, 0)"}},[_c('v-toolbar-title',{staticClass:"text-h6 white--text pl-0"},[_vm._v(" Add Tick Configuration ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"white","icon":""},on:{"click":function($event){_vm.dialog.fullscreen = !_vm.dialog.fullscreen}}},[_c('v-icon',[_vm._v("mdi-arrow-expand")])],1),_c('v-btn',{attrs:{"color":"white","icon":""},on:{"click":function($event){_vm.dialog.visible = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-form',{ref:"configForm"},[_c('v-container',[_vm._l((_vm.tickSelectionHeaders),function(header){return _c('v-row',{key:header.name,staticClass:"mt-0 mb-0"},[_c('v-col',{attrs:{"cols":"4"}},[_c('label',{staticStyle:{"display":"flex","align-items":"center","height":"100%"}},[_vm._v(" "+_vm._s(header.name)+" "),(header.name == 'Symbol')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pl-2",attrs:{"small":"","color":"primary"}},'v-icon',attrs,false),on),[_vm._v("mdi-lightbulb")])]}}],null,true)},[_c('span',[_vm._v("Only the symbol which does not have configuration will be shown as an option.")])]):_vm._e()],1)]),_c('v-col',{attrs:{"cols":"8"}},[(header.name == 'Server')?_c('v-autocomplete',{attrs:{"multiple":"","items":header.options,"item-text":"name","item-value":"value","disabled":header.disabled},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [(header.options.length > 0)?_c('v-list-item',{attrs:{"ripple":""},on:{"mousedown":function($event){$event.preventDefault();},"click":function($event){return _vm.toggleAll(header)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Select All ")])],1)],1):_vm._e(),_c('v-divider',{staticClass:"mt-2"})]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index < 2)?_c('v-chip',{attrs:{"small":""}},[_c('span',[_vm._v(_vm._s(item.name))])]):_vm._e(),(index === 2)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.tempData[header.value].length - 2)+" others) ")]):_vm._e()]}}],null,true),model:{value:(_vm.tempData[header.value]),callback:function ($$v) {_vm.$set(_vm.tempData, header.value, $$v)},expression:"tempData[header.value]"}}):_vm._e(),(header.name == 'Security')?_c('v-autocomplete',{attrs:{"multiple":"","items":header.options,"item-text":"name","item-value":"value","disabled":header.disabled},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [(header.options.length > 0)?_c('v-list-item',{attrs:{"ripple":""},on:{"mousedown":function($event){$event.preventDefault();},"click":function($event){return _vm.toggleAll(header)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Select All ")])],1)],1):_vm._e(),_c('v-divider',{staticClass:"mt-2"})]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index < 2)?_c('v-chip',{attrs:{"small":""}},[_c('span',[_vm._v(_vm._s(item.name))])]):_vm._e(),(index === 2)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.tempData[header.value].length - 2)+" others) ")]):_vm._e()]}}],null,true),model:{value:(_vm.tempData[header.value]),callback:function ($$v) {_vm.$set(_vm.tempData, header.value, $$v)},expression:"tempData[header.value]"}}):_vm._e(),(header.name == 'Symbol')?_c('v-autocomplete',{attrs:{"multiple":"","items":header.options,"item-text":"name","item-value":"value","disabled":header.disabled},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [(header.options.length > 0)?_c('v-list-item',{attrs:{"ripple":""},on:{"mousedown":function($event){$event.preventDefault();},"click":function($event){return _vm.toggleAll(header)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Select All ")])],1)],1):_vm._e(),_c('v-divider',{staticClass:"mt-2"})]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index < 2)?_c('v-chip',{attrs:{"small":""}},[_c('span',[_vm._v(_vm._s(item.name))])]):_vm._e(),(index === 2)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.tempData[header.value].length - 2)+" others) ")]):_vm._e()]}}],null,true),model:{value:(_vm.tempData[header.value]),callback:function ($$v) {_vm.$set(_vm.tempData, header.value, $$v)},expression:"tempData[header.value]"}}):_vm._e()],1)],1)}),_vm._l((_vm.tickConfigHeaders),function(header){return _c('v-row',{key:header.name,staticClass:"mt-0 mb-0"},[_c('v-col',{attrs:{"cols":"4"}},[_c('label',{staticStyle:{"display":"flex","align-items":"center","height":"100%"}},[_vm._v(" "+_vm._s(header.name)+" ")])]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"rules":_vm.inputRules,"required":"","placeholder":'Please enter a new value for ' +
                                header.name},model:{value:(_vm.tempData[header.value]),callback:function ($$v) {_vm.$set(_vm.tempData, header.value, $$v)},expression:"tempData[header.value]"}})],1)],1)})],2)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.onCancel}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.submitTickConfig}},[_vm._v(" Submit ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }