<template>
    <div>
        <v-row>
            <v-col cols="11">
                <v-tabs v-model="selectedTab">
                    <v-tab
                        v-for="tabName in tabNames"
                        :key="tabName"
                        :href="`#${tabName}`"
                    >
                        {{ tabName }}
                    </v-tab>
                </v-tabs>
            </v-col>
            <v-col style="display: flex" cols="1">
                <v-row justify="end" align="center">
                    <v-btn
                        small
                        color="blue-grey"
                        class="mr-3"
                        @click="forceReload"
                        :loading="loading"
                        ><v-icon>mdi-refresh</v-icon></v-btn
                    >
                </v-row>
            </v-col>
        </v-row>
        <v-tabs-items v-model="selectedTab">
            <v-tab-item
                v-for="tabName in tabNames"
                :value="tabName"
                :key="tabName"
            >
                <SymbolFeedConfig
                    v-if="tabName === 'Symbol Feed Config'"
                    :ref="tabName"
                    :is-act="selectedTab === tabName"
                    :act-type="selectedTab"
                    @done="handleDone"
                />
                <!-- <RuleTable
                    v-else
                    :ref="tabName"
                    :is-act="selectedTab === tabName"
                    :selected-tab="selectedTab"
                    @done="handleDone"
                /> -->
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import RuleTable from "./RuleTable";
import SymbolFeedConfig from "./SymbolFeedConfig";
import { permission } from "@components/mixins/permission";

export default {
    name: "TakerSymbolPriceChannel",
    mixins: [permission],
    components: { RuleTable, SymbolFeedConfig },
    data() {
        return {
            tabNames: [
                // "Core Symbol Rule",
                // "Price Channel Rule",
                "Symbol Feed Config"
            ],
            selectedTab: "",
            loading: false
        };
    },
    methods: {
        forceReload() {
            this.$refs[this.selectedTab][0].getData();
            this.loading = true;
        },
        handleDone() {
            this.loading = false;
        }
    },
    mounted() {
        this.selectedTab = this.tabNames[0];
    }
};
</script>
