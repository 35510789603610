<template>
    <v-dialog
        v-model="showDialog"
        width="1300"
        :hide-overlay="true"
        :persistent="true"
    >
        <v-card class="pt-0">
            <v-app-bar
                flat
                color="rgba(0, 0, 0, 0)"
                style="background: rgb(26, 57, 88)"
            >
                <v-toolbar-title class="text-h6 white--text pl-0"
                    >Settings updates</v-toolbar-title
                >
                <v-spacer></v-spacer>

                <v-btn
                    color="white"
                    icon
                    @click="showDialog = false"
                    :disabled="loading"
                    ><v-icon>mdi-close</v-icon></v-btn
                >
            </v-app-bar>

            <v-row>
                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <v-data-table
                        :loading="loading"
                        dense
                        :headers="header"
                        :items="uploadDiff"
                        item-key="id"
                        class="elevation-0"
                        :items-per-page="-1"
                        id="update-setting-table"
                        :hide-default-footer="true"
                        :header-props="{ sortIcon: null }"
                        fixed-header
                    >
                        <template
                            v-if="type == 'book'"
                            v-slot:item.broker_name="{ item }"
                        >
                            {{
                                brokerData.filter(
                                    (data) => data.id == item.broker_id
                                )[0].broker_name
                            }}
                        </template>
                        <template
                            v-if="type == 'routing'"
                            v-slot:item.book_a_name="{ item }"
                        >
                            <div v-if="!!item.book_a_id">
                                {{ item.book_a_name }}
                            </div>
                            <v-tooltip v-else top>
                                <template v-slot:activator="{ on, attrs }">
                                    <div
                                        v-bind="attrs"
                                        v-on="on"
                                        style="color: red"
                                    >
                                        {{ item.book_a_name }}
                                    </div>
                                </template>
                                <span>{{
                                    "The provided book name is not recognized by the book settings, hence will not be recorded"
                                }}</span>
                            </v-tooltip>
                        </template>
                        <template
                            v-if="type == 'routing'"
                            v-slot:item.book_b_name="{ item }"
                        >
                            <div v-if="!!item.book_b_id">
                                {{ item.book_b_name }}
                            </div>
                            <v-tooltip v-else top>
                                <template v-slot:activator="{ on, attrs }">
                                    <div
                                        v-bind="attrs"
                                        v-on="on"
                                        style="color: red"
                                    >
                                        {{ item.book_b_name }}
                                    </div>
                                </template>
                                <span>{{
                                    "The provided book name is not recognized by the book settings, hence will not be recorded"
                                }}</span>
                            </v-tooltip>
                        </template>
                        <template
                            v-if="type == 'book'"
                            v-slot:item.parent_book_name="{ item }"
                        >
                            {{ getParentBookName(item) }}
                        </template>
                        <template
                            v-if="type == 'copy_trade'"
                            v-slot:item="{ item }"
                        >
                            <tr>
                                <td column="id" class="fixCol_first">
                                    {{ item.id }}
                                </td>
                                <td column="server_id" class="fixCol_second">
                                    {{ getServerName(item.server_id) }}
                                </td>
                                <td column="SourceLogin" class="fixCol_third">
                                    {{ item.SourceLogin }}
                                </td>
                                <td column="TargetLogin" class="fixCol_forth">
                                    {{ item.TargetLogin }}
                                </td>

                                <td column="VerifyTimeRange">
                                    {{ item.VerifyTimeRange }}
                                </td>
                                <td column="PartialFollowerTimeRange">
                                    {{ item.PartialFollowerTimeRange }}
                                </td>
                                <td column="MobileRatio">
                                    {{ item.MobileRatio }}%
                                </td>
                                <td column="ClientRatio">
                                    {{ item.ClientRatio }}%
                                </td>
                                <td column="ExpertRatio">
                                    {{ item.ExpertRatio }}%
                                </td>
                                <td column="TP">{{ item.TP }}</td>
                                <td column="SL">{{ item.SL }}</td>
                                <td column="IsReverse">
                                    {{ item.IsReverse === 1 ? true : false }}
                                </td>
                                <td column="IsFollowPendingOrder">
                                    {{
                                        item.IsFollowPendingOrder === 1
                                            ? true
                                            : false
                                    }}
                                </td>
                                <td column="PendingRatios">
                                    {{ item.PendingRatios }}
                                </td>
                                <td column="CommentPrefix">
                                    {{ item.CommentPrefix }}
                                </td>
                                <td column="SymbolSuffix">
                                    {{ item.SymbolSuffix }}
                                </td>
                                <td column="MinLotsTrigger">
                                    {{
                                        item.MinLotsTrigger === 1 ? true : false
                                    }}
                                </td>
                                <td column="StopOrLimit">
                                    {{ item.StopOrLimit === 1 ? true : false }}
                                </td>
                                <td column="PendingPoints">
                                    {{ item.PendingPoints }}
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>

            <v-card-actions
                ><v-spacer></v-spacer>
                <v-btn
                    color="red"
                    :loading="loading"
                    text
                    @click="showDialog = false"
                >
                    Cancel
                </v-btn>
                <v-btn color="green" :loading="loading" @click="onConfirm" text>
                    Confirm
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { putBooks } from "@services/books";
import { mapState, mapActions } from "vuex";
import { snackbar } from "@components/mixins/snackbar";
import { putBroker } from "@services/broker";
import { putServer } from "@services/server";
import { putRouting } from "@services/routing";
import { updateConfig } from "@services/alert/config";
import { updateCopyTradeConfig } from "@services/copy-trade/config";
export default {
    mixins: [snackbar],
    props: {
        type: null,
        addition: null,
        edition: null,
        header: null
    },
    data() {
        return {
            showDialog: false,
            loading: false
        };
    },
    watch: {
        uploadDiff(nv) {
            if (nv.length) {
                this.showDialog = true;
            }
        }
    },
    computed: {
        ...mapState("SettingsBooks", ["bookData"]),
        ...mapState("SettingsBroker", ["brokerData"]),
        uploadDiff() {
            return [...this.edition, ...this.addition];
        },
        ...mapState("SettingsServer", ["serverData"])
    },
    methods: {
        ...mapActions("SettingsBroker", ["getBrokerAction"]),
        ...mapActions("SettingsServer", ["getServerAction"]),
        ...mapActions("SettingsBooks", ["getBooksAction"]),
        ...mapActions("Config", ["getConfigDataAction"]),
        ...mapActions("SettingsRouting", ["getRoutingsAction"]),
        ...mapActions("SettingsCopyTrade", [
            "getCopyTradeConfigAction",
            "updateCopyTradeConfigAction"
        ]),
        getParentBookName(item) {
            let returnValue = this.bookData.filter(
                (data) => data.book_id == item.parent_book_id
            );
            if (!returnValue.length) {
                returnValue = item.parent_book_id;
            } else {
                returnValue = returnValue[0]?.book_name;
            }
            return returnValue;
        },

        onSuccess(msg) {
            this.loading = false;
            this.showDialog = false;
            if (msg.includes("partially")) {
                this.snackBarWarning(msg);
            } else {
                this.snackBarSuccess(msg);
            }
        },
        onError() {
            this.loading = false;
            console.log("here ");
            this.snackBarDanger("Something went wrong, please try again");
        },
        onConfirm() {
            this.loading = true;
            const params = {
                changeType: this.type,
                addition: this.addition,
                edition: this.edition
            };
            switch (this.type) {
            case "broker":
                putBroker(params)
                    .then((res) => {
                        if (res.status == 200) {
                            this.getBrokerAction();
                            this.onSuccess(res.data.msg);
                        }
                    })
                    .catch(() => {
                        this.onError();
                    });
                break;
            case "book":
                putBooks(params)
                    .then((res) => {
                        if (res.status == 200) {
                            this.getBooksAction();
                            this.onSuccess(res.data.msg);
                        }
                    })
                    .catch(() => {
                        this.onError();
                    });
                break;
            case "server":
                putServer(params)
                    .then((res) => {
                        if (res.status == 200) {
                            this.getServerAction();
                            this.onSuccess(res.data.msg);
                        }
                    })
                    .catch(() => {
                        this.onError();
                    });
                break;
            case "routing":
                params.addition = params.addition.filter(
                    (item) =>
                        item.book_a_id !== null && item.book_b_id !== null
                );
                params.edition = params.edition.filter(
                    (item) =>
                        item.book_a_id !== null && item.book_b_id !== null
                );
                putRouting(params)
                    .then((res) => {
                        if (res.status == 200) {
                            this.getRoutingsAction();
                            this.onSuccess(res.data.msg);
                        }
                    })
                    .catch(() => {
                        this.onError();
                    });
                break;
            case "copy_trade":
                updateCopyTradeConfig(params)
                    .then((res) => {
                        if (res.status == 200) {
                            this.getCopyTradeConfigAction();
                            this.onSuccess(res.data.msg);
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                        this.onError();
                    });
                break;
            case "Fast Trade":
            case "Large Exposure":
            case "Large Volume":
            case "Large Volume USD":
            case "Locking Position":
            case "Mandate":
            case "Profit Taker":
            case "Watch List":
            case "Trade On Credit":
            case "Daily Winner":
            case "Daily Loser":
            case "Large Exposure Volume":
            case "Deposit Withdrawal":
            case "Same Direction":
            case "Tick":
                const changingType = this.type
                    .toLowerCase()
                    .replaceAll(" ", "-");
                updateConfig(changingType, params)
                    .then((res) => {
                        if (res.status == 200) {
                            this.getConfigDataAction(changingType);
                            this.onSuccess(res.data.msg);
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                        this.onError();
                    });
                break;
            default:
                console.log(
                    "Add " +
                            this.type +
                            " settings in onConfirm() function of ComponentUpdateConfirmation please"
                );
            }
        },
        getServerName(serverID) {
            return (
                this.serverData.find((data) => data.id === serverID)
                    ?.server_name || "N/A"
            );
        }
    },
    mounted() {
        this.loading = false;
    }
};
</script>
<style scoped>
div >>> .v-dialog {
    overflow-x: hidden;
}
</style>
