<template>
    <div>
        <v-data-table
            dense
            :headers="configHeader"
            :items="configData"
            item-key="id"
            ref="configTable"
            :loading="loading"
            :header-props="{ sortIcon: null }"
        >
            <template v-slot:item.actions="{ item }">
                <v-icon dense @click="handleItemClick(item)" :disabled="loading"
                    >mdi-pencil</v-icon
                >
            </template>
        </v-data-table>
        <v-dialog
            v-model="dialog.visible"
            :fullscreen="dialog.fullscreen"
            width="1000"
            :hide-overlay="true"
            :persistent="true"
        >
            <v-card class="pt-0">
                <v-app-bar
                    flat
                    color="rgba(0, 0, 0, 0)"
                    style="background: rgb(26, 57, 88)"
                >
                    <v-toolbar-title class="text-h6 white--text pl-0">{{
                        dialog.title
                    }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="white"
                        icon
                        @click="dialog.fullscreen = !dialog.fullscreen"
                        ><v-icon>mdi-arrow-expand</v-icon></v-btn
                    >
                    <v-btn color="white" icon @click="dialog.visible = false"
                        ><v-icon>mdi-close</v-icon></v-btn
                    >
                </v-app-bar>

                <v-card-actions>
                    <v-row style="margin-top: 20px">
                        <v-col cols="6" sm="6" md="6" lg="6" xl="6">
                            <v-text-field
                                label="Core Symbol"
                                v-model="editingData.core_symbol"
                                disabled
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6" sm="6" md="6" lg="6" xl="6">
                            <v-text-field
                                label="Symbol Group Path"
                                v-model="editingData.symbol_group_path"
                                disabled
                            ></v-text-field>
                        </v-col>
                        <v-col cols="4" sm="4" md="4" lg="4" xl="4">
                            <v-text-field
                                label="Price Channel Id"
                                v-model="editingData.price_channel_id"
                                disabled
                            ></v-text-field>
                        </v-col>
                        <v-col cols="4" sm="4" md="4" lg="4" xl="4">
                            <v-text-field
                                label="Price Channel Id Convert"
                                v-model="editingData.price_channel_id_convert"
                                disabled
                            ></v-text-field>
                        </v-col>
                        <v-col cols="4" sm="4" md="4" lg="4" xl="4">
                            <v-text-field
                                label="Symbol"
                                v-model="editingData.symbol"
                                disabled
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                            <v-text-field
                                label="Security"
                                v-model="editingData.security"
                                disabled
                            ></v-text-field>
                        </v-col>
                        <v-col cols="4" sm="4" md="4" lg="4" xl="4">
                            <v-text-field
                                label="Feed To Taker"
                                v-model="editingData.feed_to_taker"
                                disabled
                            ></v-text-field>
                        </v-col>
                        <v-col cols="4" sm="4" md="4" lg="4" xl="4">
                            <v-text-field
                                label="Spread Ask"
                                v-model="editingData.spread_ask"
                                disabled
                            ></v-text-field>
                        </v-col>
                        <v-col cols="4" sm="4" md="4" lg="4" xl="4">
                            <v-text-field
                                label="Spread Bid"
                                v-model="editingData.spread_bid"
                                disabled
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                            <v-text-field
                                label="Accept"
                                v-model="editingData.accept"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-actions>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        :loading="btnLoading"
                        @click="handleUpdate"
                    >
                        Update
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { snackbar } from "@components/mixins/snackbar";
import { getSymbolFeedConfig, updateSymbolFeedConfig } from "@services/oz";
import deepClone from "deep-clone";

export default {
    props: ["selectedTab", "isAct"],
    mixins: [snackbar],
    data() {
        return {
            defaultOption: { page: 1 },
            configHeader: [
                { text: "Id", value: "id" },
                { text: "Core Symbol", value: "core_symbol" },
                { text: "Symbol Group Path", value: "symbol_group_path" },
                { text: "Price Channel Id", value: "price_channel_id" },
                {
                    text: "Price Channel Id Convert",
                    value: "price_channel_id_convert",
                },
                { text: "Symbol", value: "symbol" },
                { text: "Security", value: "security" },
                { text: "Feed To Taker", value: "feed_to_taker" },
                { text: "Accept", value: "accept" },
                { text: "Spread Ask", value: "spread_ask" },
                { text: "Spread Bid", value: "spread_bid" },
                { text: "Created At", value: "created_at" },
                { text: "Updated At", value: "updated_at" },
                {
                    fixed: true,
                    text: "Actions",
                    value: "actions",
                    sortable: false,
                },
            ],
            configData: [],
            loading: false,
            btnLoading: false,
            dialog: {
                title: "Edit Symbol Feed Config",
                fullscreen: false,
                visible: false,
            },
            editingData: {
                accept: "",
            },
        };
    },
    watch: {
        selectedTab: {
            immediate: true,
            handler() {
                if (this.isAct) {
                    this.getData();
                }
            },
        },
    },

    methods: {
        async getData() {
            this.loading = true;
            const res = await getSymbolFeedConfig();
            this.configData = res.data;
            this.loading = false;
            this.$emit("done");
        },
        handleItemClick(item) {
            this.dialog.visible = true;
            this.editingData = deepClone(item);
        },
        async handleUpdate() {
            const { id, accept } = this.editingData;
            try {
                this.btnLoading = true;
                const res = await updateSymbolFeedConfig({ id, accept });
                this.snackBarSuccess(res.data.msg);
                this.dialog.visible = false;
            } catch (e) {
                console.log(e);
                this.snackBarDanger("update error!");
            } finally {
                this.btnLoading = false;
            }
            this.getData();
        },
    },
};
</script>

<style scoped>
.col-4,
.col-8,
.col-6,
.col-12 {
    padding-top: 0;
    padding-bottom: 0;
}
.v-text-field {
    padding-top: 0px;
    margin-top: 0px;
}
</style>
