<template>
    <div>
        <v-row>
            <v-col cols="3">
                <!-- PK副标的搜索 -->
                <v-text-field
                    class=""
                    v-model="bookSearch"
                    outlined
                    dense
                    append-icon="mdi-search"
                    label="Search Book"
                    single-line
                    hide-details
                >
                </v-text-field>
            </v-col>

            <v-col cols="5"></v-col>

            <v-col cols="4" class="text-right">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="blue-grey"
                            :loading="loading"
                            small
                            v-bind="attrs"
                            v-on="on"
                            @click="resetFileInput"
                        >
                            <v-icon dark dense> mdi-file-upload </v-icon>
                            <input
                                type="file"
                                ref="bookFile"
                                @change="
                                    (event) => readFile(event.target.files[0])
                                "
                                accept=".csv"
                                style="display: none"
                            />
                        </v-btn>
                    </template>
                    <span>Upload settings from CSV</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="blue-grey"
                            class="ml-2"
                            :loading="loading"
                            small
                            v-bind="attrs"
                            v-on="on"
                        >
                            <download-excel
                                :data="bookData"
                                :fields="csvFields"
                                type="csv"
                                style="display: inline"
                                name="BookSettings.csv"
                                :escapeCsv="false"
                            >
                                <v-icon dark dense>
                                    mdi-microsoft-excel
                                </v-icon>
                            </download-excel>
                        </v-btn>
                    </template>
                    <span>Export settings as CSV</span>
                </v-tooltip>

                <v-btn
                    small
                    color="blue-grey"
                    class="ml-2"
                    @click="getBooksAction"
                    :loading="loading"
                    ><v-icon>mdi-refresh</v-icon></v-btn
                >

                <v-btn
                    small
                    color="primary"
                    class="ml-2"
                    @click="toggleAdd"
                    :loading="loading"
                    >ADD</v-btn
                >
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <!-- PK主表逻辑 -->
                <v-data-table
                    dense
                    :search="bookSearch"
                    :headers="bookHeader"
                    :items="bookData"
                    item-key="id"
                    class="elevation-0"
                    :items-per-page="10"
                    id="home-table"
                    :hide-default-footer="false"
                    :header-props="{ sortIcon: null }"
                    fixed-header
                    :loading="loading"
                >
                    <template v-slot:item.broker_name="{ item }">
                        {{
                            brokerData.filter(
                                (data) => data.id == item.broker_id
                            )[0].broker_name
                        }}
                    </template>
                    <template v-slot:item.parent_book_name="{ item }">
                        {{
                            bookData.filter(
                                (data) => data.book_id == item.parent_book_id
                            )[0].book_name
                        }}
                    </template>
                    <template v-slot:item.action="{ item }">
                        <v-icon
                            dense
                            v-on:click="toggleUpdate(item)"
                            :disabled="loading"
                            >mdi-pen</v-icon
                        >
                        &nbsp;
                        <v-icon
                            dense
                            :disabled="loading"
                            @click="deleteConfirmation(item)"
                            v-show="deletable(item.book_name)"
                            >mdi-delete</v-icon
                        >
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <DeleteConfirmDialog
            :confirmPopover="confirmPopover"
            type="Book"
            :name="selectedDeletion.book_name"
            :loading="loading"
            @close="
                () => {
                    confirmPopover = false;
                }
            "
            @confirm="deleteBook"
        ></DeleteConfirmDialog>

        <v-dialog
            v-model="dialog.visible"
            :fullscreen="dialog.fullscreen"
            width="500"
            :hide-overlay="true"
            :persistent="true"
        >
            <v-card class="pt-0">
                <v-app-bar
                    flat
                    color="rgba(0, 0, 0, 0)"
                    style="background: rgb(26, 57, 88)"
                >
                    <v-toolbar-title class="text-h6 white--text pl-0">{{
                        dialog.title
                    }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="white"
                        icon
                        @click="dialog.fullscreen = !dialog.fullscreen"
                        ><v-icon>mdi-arrow-expand</v-icon></v-btn
                    >
                    <v-btn color="white" icon @click="dialog.visible = false"
                        ><v-icon>mdi-close</v-icon></v-btn
                    >
                </v-app-bar>

                <v-card-actions>
                    <v-row>
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                            <v-text-field
                                label="Book Name"
                                v-model="dialog.book_name"
                                hide-details="auto"
                            ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="12"
                            md="12"
                            lg="12"
                            xl="12"
                            v-show="dialog.title == 'Add New Book'"
                        >
                            <v-autocomplete
                                label="Broker Name"
                                v-model="dialog.broker"
                                :items="brokerData"
                                item-text="broker_name"
                                item-value="id"
                                auto-select-first
                                chips
                                clearable
                                deletable-chips
                                small-chips
                                hide-details
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                            <v-autocomplete
                                label="Parent Book"
                                v-model="dialog.parent_book"
                                :items="validParentData"
                                item-text="book_name"
                                item-value="book_id"
                                auto-select-first
                                chips
                                clearable
                                deletable-chips
                                small-chips
                                hide-details
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                            <v-autocomplete
                                label="Accessible"
                                v-model="dialog.accessible"
                                :items="[true, false]"
                                auto-select-first
                                chips
                                small-chips
                            >
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                </v-card-actions>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="dialog.visible = false"
                        :disabled="dialog.loading"
                        >Cancel</v-btn
                    >

                    <v-btn
                        color="green darken-1"
                        text
                        @click="addBook"
                        :loading="loading"
                        v-show="dialog.title == 'Add New Book'"
                        ><span>Add</span></v-btn
                    >
                    <v-btn
                        color="green darken-1"
                        text
                        @click="updateBook"
                        :loading="loading"
                        v-show="dialog.title == 'Update Book'"
                        ><span>Update</span></v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
        <UpdateConfirmation
            type="book"
            :addition="addition"
            :edition="edition"
            :header="bookHeaderClean"
        />
    </div>
</template>
<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import DeleteConfirmDialog from "@components/ComponentDeleteConfirmDialog";
import validator from "@assets/js/validator";
import { snackbar } from "@components/mixins/snackbar";
import UpdateConfirmation from "./ComponentUpdateConfirmation.vue";
import deepClone from "deep-clone";

export default {
    mixins: [snackbar],
    components: { DeleteConfirmDialog, UpdateConfirmation },
    data() {
        return {
            bookSearch: "",
            dialog: {
                book_id: null,
                visible: false,
                fullscreen: false,
                title: "Add New Book",
                book_name: "",
                parent_book: "",
                accessible: true,
                broker: "",
            },
            confirmPopover: false,
            selectedDeletion: {},
            addition: [],
            edition: [],
        };
    },
    watch: {
        updated(nv) {
            if (nv) {
                this.snackBarSuccess(this.returnMsg);
                this.dialog.visible = false;
                this.confirmPopover = false;
                this.getBooksAction();
            }
        },
        "dialog.broker": function (nv) {
            if (nv == undefined) {
                this.dialog.parent_book = "";
            }
        },
    },
    computed: {
        ...mapState("SettingsBooks", [
            "bookData",
            "bookHeader",
            "loading",
            "updated",
            "returnMsg",
            "csvFields",
        ]),
        ...mapState("SettingsBroker", ["brokerData"]),
        validParentData() {
            const validOption = this.bookData.filter(
                (data) => data.broker_id == this.dialog.broker
            );
            return validOption;
        },
        bookHeaderClean() {
            const bh = deepClone(this.bookHeader).filter(
                (item) =>
                    item.value !== "updated_at" && item.value !== "created_at"
            );
            return bh;
        },
    },
    methods: {
        ...mapActions("SettingsBooks", [
            "getBooksAction",
            "postBooksAction",
            "putBooksAction",
            "deleteBooksAction",
        ]),
        ...mapMutations("SettingsBooks", ["UPDATE_STATUS"]),
        ...mapActions("SettingsBroker", ["getBrokerAction"]),

        /**
         * Toggle dialog to add book
         */
        toggleAdd() {
            this.getBrokerAction();
            this.dialog = {
                visible: true,
                fullscreen: false,
                title: "Add New Book",
                book_name: "",
                parent_book: "",
                broker: "",
                accessible: true,
            };
            this.UPDATE_STATUS(false);
        },
        toggleUpdate(data) {
            this.getBrokerAction();
            this.dialog = {
                book_id: data.book_id,
                visible: true,
                fullscreen: false,
                title: "Update Book",
                book_name: data.book_name,
                parent_book: data.parent_book_id,
                broker: data.broker_id,
                accessible: data.accessible,
            };
            this.UPDATE_STATUS(false);
        },
        addBook() {
            if (
                validator.isAnyFieldEmpty([
                    this.dialog.book_name,
                    this.dialog.parent_book,
                    this.dialog.accessible,
                    this.dialog.broker,
                ])
            ) {
                this.snackBarDanger(
                    "Please fill all of the input fields before adding new book"
                );
                return;
            }
            const params = {
                book_name: this.dialog.book_name,
                parent_book_id: this.dialog.parent_book,
                accessible: this.dialog.accessible,
                broker_id: this.dialog.broker,
            };
            this.postBooksAction(params);
        },
        updateBook() {
            if (
                validator.isAnyFieldEmpty([
                    this.dialog.book_name,
                    this.dialog.parent_book,
                    this.dialog.accessible,
                ])
            ) {
                this.snackBarDanger(
                    "Please fill all of the input fields before updating"
                );
                return;
            }
            const params = {
                book_id: this.dialog.book_id,
                book_name: this.dialog.book_name,
                parent_book_id: this.dialog.parent_book,
                accessible: this.dialog.accessible,
                broker_id: this.dialog.broker,
            };
            this.putBooksAction(params);
        },
        deleteConfirmation(item) {
            this.UPDATE_STATUS(false);
            this.confirmPopover = true;
            this.selectedDeletion = item;
        },
        deleteBook() {
            this.deleteBooksAction(this.selectedDeletion.book_id);
        },
        deletable(name) {
            if (name == "ALL" || name == "UNKNOWN_A" || name == "UNKNOWN_B") {
                return false;
            } else {
                return true;
            }
        },
        readFile(fileInput) {
            var reader = new FileReader();
            reader.onload = () => {
                var csv = reader.result
                    .replaceAll('"""', "")
                    .replaceAll('"=""', "");
                var lines = csv.split("\r\n");
                var result = [];
                var headers = lines[0].split(",");
                if (
                    JSON.stringify(headers) ===
                    JSON.stringify(Object.keys(this.csvFields))
                ) {
                    for (var i = 1; i < lines.length; i++) {
                        if (lines[i] === "") {
                            break;
                        }
                        var obj = {};
                        var currentLine = lines[i].split(",");
                        for (var j = 0; j < headers.length; j++) {
                            obj[this.csvFields[headers[j]]] = currentLine[j];
                        }
                        result.push(obj);
                    }

                    this.addition = [];
                    this.edition = [];
                    let add = [];
                    let change = [];
                    result.map((item) => {
                        const found = this.bookData.find((data) => {
                            return data.book_id.toString() === item.book_id;
                        });
                        if (found) {
                            for (const attr in item) {
                                if (item[attr] !== found[attr].toString()) {
                                    change.push(item);
                                    break;
                                }
                            }
                        } else {
                            add.push(item);
                        }
                    });
                    if (change.length === 0 && add.length === 0) {
                        this.snackBarSuccess("All settings are up to date.");
                    } else {
                        this.addition = [...add];
                        this.edition = [...change];
                    }
                } else {
                    this.snackBarDanger("Input file is invalid.");
                }
            };
            reader.readAsText(fileInput);
        },
        resetFileInput() {
            this.$refs.bookFile.value = "";
            this.$refs.bookFile.click();
        },
    },
};
</script>
