<template>
    <div>
        <v-row>
            <v-col cols="10">
                <v-tabs>
                    <v-tab
                        v-model="selectedTab"
                        v-for="tabName in tabNames"
                        :key="tabName"
                        :id="tabName"
                        v-on:click="changeTab(tabName)"
                        >{{
                            tabName == "Large Volume" ? "Big Lot" : tabName
                        }}</v-tab
                    >
                </v-tabs>
            </v-col>
            <v-col style="display: flex" cols="2">
                <v-row justify="end" align="center">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="blue-grey"
                                :loading="loading"
                                small
                                v-bind="attrs"
                                v-on="on"
                                @click="resetFileInput"
                                :disabled="!allowEditing"
                            >
                                <v-icon dark dense> mdi-file-upload </v-icon>
                                <input
                                    type="file"
                                    ref="alertFile"
                                    @change="
                                        (event) =>
                                            readFile(event.target.files[0])
                                    "
                                    accept=".csv"
                                    style="display: none"
                                />
                            </v-btn>
                        </template>
                        <span>Upload settings from CSV</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="blue-grey"
                                class="ml-2"
                                :loading="loading"
                                small
                                v-bind="attrs"
                                v-on="on"
                            >
                                <download-excel
                                    :data="configData"
                                    :fields="csvFields[selectedTab]"
                                    style="display: inline"
                                    type="csv"
                                    :name="
                                        selectedTab.replaceAll(' ', '') +
                                        'Settings.csv'
                                    "
                                    :escapeCsv="false"
                                >
                                    <v-icon dark dense>
                                        mdi-microsoft-excel
                                    </v-icon>
                                </download-excel>
                            </v-btn>
                        </template>
                        <span>Export settings as CSV</span>
                    </v-tooltip>
                    <v-btn
                        small
                        color="blue-grey"
                        class="mr-2 ml-2"
                        @click="forceReload"
                        :loading="loading"
                        ><v-icon>mdi-refresh</v-icon></v-btn
                    >
                    <TickStepper
                        v-if="selectedTab === 'Tick'"
                        :isDisabled="!allowEditing"
                    ></TickStepper>
                    <EditForm
                        v-else
                        :type="selectedTab"
                        :dataHeader="addHeader"
                        :isEditing="false"
                        :isDisabled="!allowEditing"
                    />
                </v-row>
            </v-col>
        </v-row>
        <ConfigTable :selectedType="selectedTab" :isDisabled="!allowEditing" />
        <UpdateConfirmation
            :type="selectedTab"
            :addition="addition"
            :edition="edition"
            :header="updateHeader"
        />
    </div>
</template>

<script>
import Breadcrumbs from "@components/Breadcrumbs";
import { mapState, mapActions, mapMutations } from "vuex";
import { snackbar } from "@components/mixins/snackbar";
import ConfigTable from "./alert/ConfigTable.vue";
import EditForm from "./alert/EditForm.vue";
import TickStepper from "./alert/TickStepper.vue";
import { permission } from "@components/mixins/permission";
import UpdateConfirmation from "./ComponentUpdateConfirmation.vue";
import deepClone from "deep-clone";
import { createCancelToken } from "@utils/request";

export default {
    mixins: [permission, snackbar],
    components: {
        Breadcrumbs,
        ConfigTable,
        EditForm,
        TickStepper,
        UpdateConfirmation,
    },
    data() {
        return {
            tabNames: [
                "Fast Trade",
                "Large Exposure",
                "Large Volume",
                "Large Volume USD",
                // "Weighted Volume",
                "Locking Position",
                "Mandate",
                "Profit Taker",
                "Watch List",
                "Same Direction",
                "Trade On Credit",
                "Daily Winner",
                "Daily Loser",
                "Large Exposure Volume",
                "Deposit Withdrawal",
                "Tick",
            ],
            selectedTab: "",
            allowEditing: false,
            addition: [],
            edition: [],
            cancelTokenSource: null,
        };
    },
    computed: {
        ...mapState("Config", [
            "configData",
            "updated",
            "loading",
            "csvFields",
            "headerOptions",
        ]),
        ...mapState("SettingsServer", ["serverData"]),
        ...mapState("SettingsBroker", ["brokerData"]),
        addHeader() {
            if (this.headerOptions[this.selectedTab]) {
                let filtered = deepClone(
                    this.headerOptions[this.selectedTab]
                ).filter(
                    (item) =>
                        item.value !== "id" &&
                        item.value !== "updated_at" &&
                        item.value !== "created_at" &&
                        item.value !== "actions"
                );
                return filtered;
            } else {
                return [];
            }
        },
        updateHeader() {
            if (this.headerOptions[this.selectedTab]) {
                let filtered = deepClone(
                    this.headerOptions[this.selectedTab]
                ).filter(
                    (item) =>
                        item.value !== "updated_at" &&
                        item.value !== "created_at" &&
                        item.value !== "actions"
                );
                return filtered;
            }
        },
    },
    watch: {
        updated(nv) {
            if (nv) {
                this.getConfigData(this.selectedTab);
            }
        },
    },
    methods: {
        ...mapActions("Config", ["getConfigDataAction"]),
        ...mapMutations("Config", ["CHANGE_UPDATE_STATUS"]),
        changeTab(name) {
            this.selectedTab = name;
            this.getConfigData(name);
        },
        getConfigData(tabName) {
            if (this.cancelTokenSource) {
                this.cancelTokenSource.cancel(
                    "Operation canceled due to new request."
                );
            }

            this.cancelTokenSource = createCancelToken();

            this.getConfigDataAction({
                tabName: tabName,
                cancelTokenSource: this.cancelTokenSource,
            });
        },
        forceReload() {
            this.getConfigData(this.selectedTab);
        },
        readFile(fileInput) {
            var reader = new FileReader();
            reader.onload = () => {
                var csv = reader.result
                    .replaceAll('"""', "")
                    .replaceAll('"=""', "");
                var lines = csv.split("\r\n");
                var result = [];
                var headers = lines[0].split(",");
                if (
                    JSON.stringify(headers) ===
                    JSON.stringify(
                        Object.keys(this.csvFields[this.selectedTab])
                    )
                ) {
                    for (var i = 1; i < lines.length; i++) {
                        if (lines[i] === "") {
                            break;
                        }
                        var obj = {};
                        var currentLine = lines[i].split(",");
                        var start = null;
                        var end = null;
                        for (var x = 0; x < currentLine.length; x++) {
                            if (
                                currentLine[x].includes("[") &&
                                currentLine[x].includes("]")
                            ) {
                                continue;
                            }
                            if (currentLine[x].includes("[") && start == null) {
                                start = x;
                                continue;
                            }
                            if (currentLine[x].includes("]") && end == null) {
                                end = x;
                            }
                            if (start && end) {
                                const left = currentLine.slice(0, start);
                                const middle = currentLine
                                    .slice(start, end + 1)
                                    .join(",")
                                    .replaceAll('"', "");
                                const right = currentLine.slice(end + 1);
                                currentLine = [...left, middle, ...right];
                                x = start;
                                start = null;
                                end = null;
                            }
                        }
                        if (start || end) {
                            continue;
                        }

                        for (var j = 0; j < headers.length; j++) {
                            obj[this.csvFields[this.selectedTab][headers[j]]] =
                                currentLine[j];
                        }
                        result.push(obj);
                    }

                    this.addition = [];
                    this.edition = [];
                    let add = [];
                    let change = [];
                    result.map((item) => {
                        const found = this.configData.find(
                            (data) => data.id.toString() === item.id
                        );
                        if (found) {
                            for (const attr in item) {
                                if (item[attr] !== found[attr].toString()) {
                                    change.push(item);
                                    break;
                                }
                            }
                        } else {
                            add.push(item);
                        }
                    });
                    if (change.length === 0 && add.length === 0) {
                        this.snackBarSuccess("All settings are up to date.");
                    } else {
                        this.addition = [...add];
                        this.edition = [...change];
                    }
                } else {
                    this.snackBarDanger("Input file is invalid.");
                }
            };
            reader.readAsText(fileInput);
        },
        resetFileInput() {
            this.$refs.alertFile.value = "";
            this.$refs.alertFile.click();
        },
    },
    mounted() {
        const alertPermission = JSON.parse(localStorage.getItem("permission"))
            .frontPermission.settings;

        this.allowEditing =
            alertPermission.function.includes("alertConfigEdit");
        this.changeTab(this.tabNames[0]);
    },
};
</script>
