<template>
    <v-dialog
        v-model="dialog.visible"
        :fullscreen="dialog.fullscreen"
        width="700"
    >
        <template v-slot:activator="{ on }">
            <v-btn
                color="primary"
                class="mr-3"
                v-on="on"
                small
                @click="getServerOptions"
                :disabled="isDisabled"
            >
                ADD
            </v-btn>
        </template>

        <v-card>
            <v-app-bar
                flat
                color="rgba(0, 0, 0, 0)"
                style="background: rgb(26, 57, 88)"
            >
                <v-toolbar-title class="text-h6 white--text pl-0">
                    Add Tick Configuration
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                    color="white"
                    icon
                    @click="dialog.fullscreen = !dialog.fullscreen"
                    ><v-icon>mdi-arrow-expand</v-icon></v-btn
                >
                <v-btn color="white" icon @click="dialog.visible = false"
                    ><v-icon>mdi-close</v-icon></v-btn
                >
            </v-app-bar>
            <v-form ref="configForm">
                <v-container>
                    <v-row
                        v-for="header in tickSelectionHeaders"
                        :key="header.name"
                        class="mt-0 mb-0"
                    >
                        <v-col cols="4">
                            <label
                                style="
                                    display: flex;
                                    align-items: center;
                                    height: 100%;
                                "
                            >
                                {{ header.name }}
                                <v-tooltip top v-if="header.name == 'Symbol'">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                            small
                                            class="pl-2"
                                            color="primary"
                                            v-bind="attrs"
                                            v-on="on"
                                            >mdi-lightbulb</v-icon
                                        >
                                    </template>
                                    <span
                                        >Only the symbol which does not have
                                        configuration will be shown as an
                                        option.</span
                                    >
                                </v-tooltip>
                            </label>
                        </v-col>
                        <v-col cols="8">
                            <v-autocomplete
                                v-if="header.name == 'Server'"
                                multiple
                                v-model="tempData[header.value]"
                                :items="header.options"
                                item-text="name"
                                item-value="value"
                                :disabled="header.disabled"
                            >
                                <template v-slot:prepend-item>
                                    <v-list-item
                                        ripple
                                        @mousedown.prevent
                                        @click="toggleAll(header)"
                                        v-if="header.options.length > 0"
                                    >
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                Select All
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class="mt-2"></v-divider>
                                </template>
                                <template v-slot:selection="{ item, index }">
                                    <v-chip small v-if="index < 2">
                                        <span>{{ item.name }}</span>
                                    </v-chip>
                                    <span
                                        v-if="index === 2"
                                        class="grey--text text-caption"
                                    >
                                        (+{{
                                            tempData[header.value].length - 2
                                        }}
                                        others)
                                    </span>
                                </template>
                            </v-autocomplete>
                            <v-autocomplete
                                v-if="header.name == 'Security'"
                                multiple
                                v-model="tempData[header.value]"
                                :items="header.options"
                                item-text="name"
                                item-value="value"
                                :disabled="header.disabled"
                            >
                                <template v-slot:prepend-item>
                                    <v-list-item
                                        ripple
                                        @mousedown.prevent
                                        @click="toggleAll(header)"
                                        v-if="header.options.length > 0"
                                    >
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                Select All
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class="mt-2"></v-divider>
                                </template>
                                <template v-slot:selection="{ item, index }">
                                    <v-chip small v-if="index < 2">
                                        <span>{{ item.name }}</span>
                                    </v-chip>
                                    <span
                                        v-if="index === 2"
                                        class="grey--text text-caption"
                                    >
                                        (+{{
                                            tempData[header.value].length - 2
                                        }}
                                        others)
                                    </span>
                                </template>
                            </v-autocomplete>
                            <v-autocomplete
                                v-if="header.name == 'Symbol'"
                                multiple
                                v-model="tempData[header.value]"
                                :items="header.options"
                                item-text="name"
                                item-value="value"
                                :disabled="header.disabled"
                            >
                                <template v-slot:prepend-item>
                                    <v-list-item
                                        ripple
                                        @mousedown.prevent
                                        @click="toggleAll(header)"
                                        v-if="header.options.length > 0"
                                    >
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                Select All
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class="mt-2"></v-divider>
                                </template>
                                <template v-slot:selection="{ item, index }">
                                    <v-chip small v-if="index < 2">
                                        <span>{{ item.name }}</span>
                                    </v-chip>
                                    <span
                                        v-if="index === 2"
                                        class="grey--text text-caption"
                                    >
                                        (+{{
                                            tempData[header.value].length - 2
                                        }}
                                        others)
                                    </span>
                                </template>
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row
                        v-for="header in tickConfigHeaders"
                        :key="header.name"
                        class="mt-0 mb-0"
                    >
                        <v-col cols="4">
                            <label
                                style="
                                    display: flex;
                                    align-items: center;
                                    height: 100%;
                                "
                            >
                                {{ header.name }}
                            </label>
                        </v-col>
                        <v-col cols="8">
                            <v-text-field
                                v-model="tempData[header.value]"
                                :rules="inputRules"
                                required
                                :placeholder="
                                    'Please enter a new value for ' +
                                    header.name
                                "
                            ></v-text-field
                        ></v-col>
                    </v-row>
                </v-container>
            </v-form>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="onCancel"> Cancel </v-btn>
                <v-btn color="primary" text @click="submitTickConfig">
                    Submit
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { snackbar } from "@components/mixins/snackbar";

export default {
    mixins: [snackbar],
    props: ["isDisabled"],
    data() {
        return {
            dialog: { visible: false, fullscreen: false },
            tempData: { serverId: [], security: [], symbol: [] },
            inputRules: [(v) => !!v || "input is required"],
            tickConfigHeaders: [
                {
                    name: "Seconds Threshold",
                    value: "seconds_threshold",
                },
                {
                    name: "Continuous Count",
                    value: "continuous_count",
                },
                {
                    name: "Email Threshold",
                    value: "email_threshold",
                },
                {
                    name: "Telegram ID",
                    value: "telegram_id",
                },
            ],
        };
    },
    computed: {
        ...mapState("Config", [
            "serverOptions",
            "securityOptions",
            "symbolOptions",
        ]),
        tickSelectionHeaders() {
            return [
                {
                    name: "Server",
                    value: "serverId",
                    disabled: false,
                    options: this.serverOptions,
                },
                {
                    name: "Security",
                    value: "security",
                    disabled: this.tempData["serverId"].length == 0,
                    options: this.securityOptions,
                },
                {
                    name: "Symbol",
                    value: "symbol",
                    disabled:
                        this.tempData["security"].length == 0 ||
                        this.tempData["serverId"].length == 0,
                    options: this.symbolOptions,
                },
            ];
        },
    },
    watch: {
        "dialog.visible"(nv) {
            if (nv == true) {
                if (!!this.$refs.configForm) {
                    this.$refs.configForm.reset();
                }
            }
        },
        "tempData.serverId"(nv, ov) {
            if (JSON.stringify(nv) !== JSON.stringify(ov)) {
                this.tempData.security = [];
                this.tempData.symbol = [];
                if (nv.length > 0) {
                    this.getSecurityOptionsAction(nv);
                }
            }
        },
        "tempData.security"(nv, ov) {
            if (JSON.stringify(nv) !== JSON.stringify(ov)) {
                this.tempData.symbol = [];
                if (this.tempData.serverId.length > 0 && nv.length > 0) {
                    const params = {
                        security: nv.join(","),
                    };
                    this.getSymbolOptionsAction(params);
                }
            }
        },
    },
    methods: {
        ...mapMutations("Config", [
            "UPDATE_SYMBOL_OPTIONS",
            "UPDATE_SECURITY_OPTIONS",
        ]),
        ...mapActions("Config", [
            "getServerOptionsAction",
            "getSecurityOptionsAction",
            "getSymbolOptionsAction",
            "createConfigDataAction",
        ]),
        onCancel() {
            this.$refs.configForm.reset();
            this.dialog.visible = false;
            this.dialog.fullscreen = false;
        },
        getServerOptions() {
            this.getServerOptionsAction();
        },
        submitTickConfig() {
            let valid = true;
            for (const data of this.tickSelectionHeaders) {
                valid = valid && this.tempData[data.value].length !== 0;
                if (valid) {
                    continue;
                } else {
                    break;
                }
            }
            if (valid) {
                for (const data of this.tickConfigHeaders) {
                    valid = valid && !!this.tempData[data.value];
                    if (valid) {
                        continue;
                    } else {
                        break;
                    }
                }
            }

            this.tempData.type = "Tick";
            if (valid == false) {
                this.snackBarDanger(
                    "Please complete the form before submission."
                );
            } else {
                this.createConfigDataAction(this.tempData);
                this.dialog.visible = false;
                this.dialog.fullscreen = false;
            }
        },
        toggleAll(header) {
            this.$nextTick(() => {
                if (
                    this.tempData[header.value]?.length == header.options.length
                ) {
                    this.tempData[header.value] = [];
                } else {
                    this.tempData[header.value] = header.options
                        .slice()
                        .map((item) => item.value);
                }
            });
        },
    },
};
</script>

<style scoped>
.col-4,
.col-8 {
    padding-top: 0;
    padding-bottom: 0;
}
.v-text-field {
    padding-top: 0px;
    margin-top: 0px;
}
</style>
