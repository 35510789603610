<template>
    <div>
        <v-data-table
            dense
            :headers="configHeader"
            :items="configData"
            item-key="id"
            ref="configTable"
            :loading="loading"
            :header-props="{ sortIcon: null }"
        >
            <template v-slot:item.actions="{ item }">
                <v-icon dense @click="handleItemClick(item)">mdi-pencil</v-icon>
            </template>
        </v-data-table>
        <v-dialog
            v-model="dialog.visible"
            :fullscreen="dialog.fullscreen"
            width="1300"
            :hide-overlay="true"
            :persistent="true"
        >
            <v-card class="pt-0">
                <v-app-bar
                    flat
                    color="rgba(0, 0, 0, 0)"
                    style="background: rgb(26, 57, 88)"
                >
                    <v-toolbar-title class="text-h6 white--text pl-0">{{
                        dialog.title
                    }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="white"
                        icon
                        @click="dialog.fullscreen = !dialog.fullscreen"
                        ><v-icon>mdi-arrow-expand</v-icon></v-btn
                    >
                    <v-btn color="white" icon @click="dialog.visible = false"
                        ><v-icon>mdi-close</v-icon></v-btn
                    >
                </v-app-bar>

                <v-card-actions>
                    <v-row>
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                            <JsonEditor
                                v-if="loadJson"
                                ref="jsonEditor"
                                v-model="editingData.rules"
                            />
                        </v-col>
                    </v-row>
                </v-card-actions>

                <v-card-actions>
                    <v-btn
                        color="green darken-1"
                        text
                        :loading="btnLoading"
                        @click="handleUpdate"
                    >
                        Update
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { snackbar } from "@components/mixins/snackbar";
import {
    getCoreSymbolRule,
    updateCoreSymbolRule,
    getPriceChannelRule,
    updatePriceChannelRule,
} from "@services/oz";
import JsonEditor from "@components/ComponentJsonEditor";
import deepClone from "deep-clone";

export default {
    props: ["selectedTab", "isAct"],
    components: { JsonEditor },
    mixins: [snackbar],
    data() {
        return {
            defaultOption: { page: 1 },
            configHeader: [
                { text: "Id", value: "id", width: "3%" },
                {
                    text: "Rules",
                    value: "rules",
                    width: "70%",
                    cellClass: "setting-rule-cell",
                },
                { text: "Created At", value: "created_at", width: "10%" },
                { text: "Updated At", value: "updated_at", width: "10%" },
                {
                    fixed: true,
                    text: "Actions",
                    value: "actions",
                    width: "7%",
                    sortable: false,
                },
            ],
            configData: [],
            loading: false,
            btnLoading: false,
            dialog: {
                title: `Edit ${this.selectedTab}`,
                fullscreen: false,
                visible: false,
            },
            editingData: {
                rules: "",
            },
            loadJson: false,
        };
    },
    watch: {
        selectedTab: {
            immediate: true,
            handler() {
                if (this.isAct) {
                    this.getData();
                }
            },
        },
    },
    methods: {
        async getData() {
            this.loading = true;
            let res = {};
            if (this.selectedTab === "Core Symbol Rule") {
                res = await getCoreSymbolRule();
            } else {
                res = await getPriceChannelRule();
            }
            this.configData = res.data;
            this.loading = false;
            this.$emit("done");
        },
        handleItemClick(item) {
            this.dialog.visible = true;
            const temp = deepClone(item);
            temp.rules = JSON.parse(temp.rules);
            this.editingData = temp;
            this.loadJson = false;
            this.$nextTick(() => {
                this.loadJson = true;
            });
        },
        async handleUpdate() {
            const params = {
                id: this.editingData.id,
                rules: this.$refs.jsonEditor.getValue(),
            };
            try {
                this.btnLoading = true;
                let res = {};
                if (this.type === "Core Symbol Rule") {
                    res = await updateCoreSymbolRule(params);
                } else {
                    res = await updatePriceChannelRule(params);
                }
                this.snackBarSuccess(res.data.msg);
                this.dialog.visible = false;
            } catch (e) {
                console.log(e);
                this.snackBarDanger("update error!");
            } finally {
                this.btnLoading = false;
            }
            this.getData();
        },
    },
};
</script>
<style lang="scss">
.setting-rule-cell {
    word-break: break-word;
}
</style>
