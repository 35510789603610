<template>
    <div>
        <v-row v-if="selectedType === 'Tick'" v-show="!isDisabled">
            <v-col cols="2" style="padding-left: 2%; padding-top: 0px"
                ><v-text-field
                    :disabled="selectedIds.length == 0"
                    hide-details
                    label="Count"
                    v-model="batchCount"
                ></v-text-field
            ></v-col>
            <v-col cols="3" style="padding-top: 0px"
                ><v-text-field
                    :disabled="selectedIds.length == 0"
                    hide-details
                    label="Seconds Threshold"
                    v-model="batchThreshold"
                ></v-text-field
            ></v-col>
            <v-col cols="2" style="padding-top: 0px"
                ><v-text-field
                    :disabled="selectedIds.length == 0"
                    hide-details
                    label="Email Threshold"
                    v-model="batchEmail"
                ></v-text-field
            ></v-col>
            <v-col cols="2" style="padding-top: 0px"
                ><v-text-field
                    :disabled="selectedIds.length == 0"
                    hide-details
                    label="Telegram ID"
                    v-model="batchTelegram"
                ></v-text-field
            ></v-col>
            <v-col cols="3" class="pt-4 text-right">
                <v-btn
                    small
                    :disabled="selectedIds.length == 0"
                    color="red"
                    @click="batchUpdate"
                    class="mr-2"
                >
                    update
                </v-btn>
                <download-excel
                    :data="missingConfig"
                    :fields="csvFields['Tick']"
                    style="display: inline"
                    type="csv"
                    :name="'Tick Missing Config.csv'"
                    :escapeCsv="false"
                >
                    <v-btn
                        small
                        :disabled="missingConfig.length == 0"
                        color="orange"
                    >
                        download missing config
                    </v-btn>
                </download-excel>
            </v-col>
        </v-row>

        <v-data-table
            :show-select="selectedType === 'Tick' && !isDisabled"
            dense
            :headers="configHeader"
            :items="configData"
            item-key="id"
            ref="configTable"
            v-model="selectedIds"
            :options.sync="defaultOption"
            :loading="loading"
            :header-props="{ sortIcon: null }"
        >
            <template
                v-if="selectedType !== 'Tick'"
                v-slot:item.server_id="{ item }"
            >
                {{
                    !!serverData.find((data) => data.id === item.server_id)
                        ? serverData.find((data) => data.id === item.server_id)
                              .server_name
                        : item.server_id
                }}
            </template>
            <template
                v-if="selectedType !== 'Tick'"
                v-slot:item.broker_id="{ item }"
            >
                {{
                    brokerData.find((data) => data.id === item.broker_id)
                        .broker_name
                }}
            </template>
            <template
                v-if="selectedType === 'Tick'"
                v-slot:header.id="{ header }"
            >
                {{ header.text }}
                <v-menu offset-y :close-on-content-click="false" top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            v-bind="attrs"
                            v-on="on"
                            @click.prevent="onSearch('idSearch')"
                        >
                            mdi-magnify
                        </v-icon>
                    </template>
                    <div style="background-color: #1e1e1e; width: 280px">
                        <v-text-field
                            v-model="searchFields['id']"
                            class="px-4"
                            type="text"
                            ref="idSearch"
                            label="Enter the search term"
                            style="width: 100%"
                            single-line
                            hide-details
                        ></v-text-field>
                        <v-btn
                            @click="searchFields['id'] = ''"
                            small
                            text
                            color="primary"
                            class="ml-2 mb-2"
                            style="padding: 6px"
                        >
                            Clean
                        </v-btn>
                    </div>
                </v-menu>
            </template>

            <template
                v-if="selectedType === 'Tick'"
                v-slot:header.server_name="{ header }"
            >
                {{ header.text }}
                <v-menu offset-y :close-on-content-click="false" top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            v-bind="attrs"
                            v-on="on"
                            @click.prevent="onSearch('serverSearch')"
                        >
                            mdi-magnify
                        </v-icon>
                    </template>
                    <div style="background-color: #1e1e1e; width: 280px">
                        <v-text-field
                            v-model="searchFields['server_name']"
                            class="px-4"
                            type="text"
                            ref="serverSearch"
                            label="Enter the search term"
                            style="width: 100%"
                            single-line
                            hide-details
                        ></v-text-field>
                        <v-btn
                            @click="searchFields['server_name'] = ''"
                            small
                            text
                            color="primary"
                            class="ml-2 mb-2"
                            style="padding: 6px"
                        >
                            Clean
                        </v-btn>
                    </div>
                </v-menu>
            </template>

            <template
                v-if="selectedType === 'Tick'"
                v-slot:header.symbol="{ header }"
            >
                {{ header.text }}
                <v-menu offset-y :close-on-content-click="false" top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            v-bind="attrs"
                            v-on="on"
                            @click.prevent="onSearch('symbolSearch')"
                        >
                            mdi-magnify
                        </v-icon>
                    </template>
                    <div style="background-color: #1e1e1e; width: 280px">
                        <v-text-field
                            v-model="searchFields['symbol']"
                            class="px-4"
                            type="text"
                            ref="symbolSearch"
                            label="Enter the search term"
                            style="width: 100%"
                            single-line
                            hide-details
                        ></v-text-field>
                        <v-btn
                            @click="searchFields['symbol'] = ''"
                            small
                            text
                            color="primary"
                            class="ml-2 mb-2"
                            style="padding: 6px"
                        >
                            Clean
                        </v-btn>
                    </div>
                </v-menu>
            </template>

            <template
                v-if="selectedType === 'Tick'"
                v-slot:header.security="{ header }"
            >
                {{ header.text }}
                <v-menu offset-y :close-on-content-click="false" top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            v-bind="attrs"
                            v-on="on"
                            @click.prevent="onSearch('securitySearch')"
                        >
                            mdi-magnify
                        </v-icon>
                    </template>
                    <div style="background-color: #1e1e1e; width: 280px">
                        <v-text-field
                            v-model="searchFields['security']"
                            class="px-4"
                            type="text"
                            ref="securitySearch"
                            label="Enter the search term"
                            style="width: 100%"
                            single-line
                            hide-details
                        ></v-text-field>
                        <v-btn
                            @click="searchFields['security'] = ''"
                            small
                            text
                            color="primary"
                            class="ml-2 mb-2"
                            style="padding: 6px"
                        >
                            Clean
                        </v-btn>
                    </div>
                </v-menu>
            </template>

            <template v-slot:item.actions="{ item }">
                <EditForm
                    :type="selectedType"
                    :originalData="item"
                    :dataHeader="editHeader"
                    :isEditing="true"
                    :isDisabled="isDisabled"
                />
                <v-icon
                    dense
                    :disabled="isDisabled || loading"
                    @click="
                        dialogRemove.visible = true;
                        removeData = [item];
                    "
                    >mdi-delete</v-icon
                >
            </template>
        </v-data-table>
        <v-dialog
            v-model="dialogRemove.visible"
            :fullscreen="dialogRemove.fullscreen"
            width="1300"
            :hide-overlay="true"
            :persistent="true"
        >
            <v-card class="pt-0">
                <v-app-bar
                    flat
                    color="rgba(0, 0, 0, 0)"
                    style="background: rgb(26, 57, 88)"
                >
                    <v-toolbar-title class="text-h6 white--text pl-0">{{
                        dialogRemove.title
                    }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="white"
                        icon
                        @click="
                            dialogRemove.fullscreen = !dialogRemove.fullscreen
                        "
                        ><v-icon>mdi-arrow-expand</v-icon></v-btn
                    >
                    <v-btn
                        color="white"
                        icon
                        @click="dialogRemove.visible = false"
                        ><v-icon>mdi-close</v-icon></v-btn
                    >
                </v-app-bar>

                <v-card-actions>
                    <v-row>
                        <v-col
                            cols="12"
                            sm="12"
                            md="12"
                            lg="12"
                            xl="12"
                        ></v-col>
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                            <v-data-table
                                dense
                                :headers="
                                    configHeader.filter(
                                        (item) => item.value !== 'actions'
                                    )
                                "
                                :items="removeData"
                                item-key="id"
                                class="elevation-0"
                                :loading="loading"
                                :items-per-page="-1"
                                id="home-table"
                                :hide-default-footer="true"
                                fixed-header
                                :header-props="{ sortIcon: null }"
                            >
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card-actions>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="dialogRemove.visible = false"
                        :disabled="loading"
                        >Cancel</v-btn
                    >
                    <v-btn
                        color="red darken-1"
                        text
                        @click="confirmDeletion()"
                        :loading="loading"
                        ><span>Confirm</span></v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import EditForm from "./EditForm.vue";
import { snackbar } from "@components/mixins/snackbar";
import { getTickMissingConfig } from "@services/alert/config";

export default {
    props: ["selectedType", "isDisabled"],
    components: { EditForm },
    mixins: [snackbar],
    data() {
        return {
            selectedIds: [],
            defaultOption: { page: 1 },
            searchFields: {
                id: "",
                server_name: "",
                symbol: "",
                security: "",
            },
            batchThreshold: null,
            batchCount: null,
            batchEmail: null,
            batchTelegram: null,
            configHeader: [],
            dialogRemove: {
                title: "Are you sure you want to remove this record?",
                fullscreen: false,
                visible: false,
            },
            removeData: null,
            missingConfig: [],
        };
    },
    computed: {
        ...mapState("Config", [
            "configData",
            "tabName",
            "loading",
            "updated",
            "headerOptions",
            "csvFields",
        ]),
        ...mapState("SettingsServer", ["serverData"]),
        ...mapState("SettingsBroker", ["brokerData"]),
        editHeader() {
            let temp = JSON.parse(JSON.stringify(this.configHeader)).filter(
                (item) => {
                    return (
                        item.value !== "created_at" &&
                        item.value !== "updated_at" &&
                        item.value !== "security"
                    );
                }
            );
            return temp;
        },
    },
    watch: {
        tabName(nv) {
            this.$refs.configTable.options.page = 1;
            if (nv === "tick") {
                getTickMissingConfig().then(({ data }) => {
                    if (data.length > 0) {
                        this.missingConfig = data.map((item) => {
                            return {
                                id: item.id,
                                server_id: item.server_id,
                                server_name: this.serverData.find(
                                    (serverInfo) =>
                                        serverInfo.id === item.server_id
                                ).server_name,
                                symbol: item.name,
                                security: item.security,
                                continuous_count: null,
                                seconds_threshold: null,
                                email_threshold: null,
                                telegram_id: null,
                            };
                        });
                    }
                });
                Object.keys(this.searchFields).forEach((key) => {
                    this.searchFields[key] = "";
                });
            }
            this.selectedIds = [];
        },
        updated(nv) {
            if (nv) {
                this.dialogRemove.visible = false;
                this.selectedIds = [];
                this.batchThreshold = null;
                this.batchCount = null;
                this.batchEmail = null;
                this.batchTelegram = null;
                setTimeout(() => {
                    this.getConfigDataAction(this.selectedType);
                }, 500);
            }
        },
        selectedType(nv) {
            this.configHeader = this.headerOptions[nv];
            if (nv === "Tick") {
                const filterFields = [
                    "id",
                    "server_name",
                    "symbol",
                    "security",
                ];
                for (const field of filterFields) {
                    this.configHeader.find(
                        (item) => item["value"] === field
                    ).filter = (value) => {
                        if (this.searchFields[field] === "") return value;
                        return (
                            value
                                .toString()
                                .toLowerCase()
                                .indexOf(
                                    this.searchFields[field].toLowerCase()
                                ) !== -1
                        );
                    };
                }
            }
        },
    },
    methods: {
        ...mapActions("Config", [
            "updateConfigDataAction",
            "getConfigDataAction",
            "deleteConfigDataAction",
        ]),
        onSearch(field) {
            setTimeout(() => {
                this.$refs[field].focus();
            }, 200);
        },
        confirmDeletion() {
            this.deleteConfigDataAction({
                id: this.removeData[0].id,
                type: this.selectedType,
            });
        },
        batchUpdate() {
            if (
                !!this.batchThreshold ||
                !!this.batchCount ||
                !!this.batchEmail ||
                !!this.batchTelegram
            ) {
                const data = { type: "Tick" };
                const list = this.selectedIds.map((item) => {
                    let obj = { ...item };
                    if (!!this.batchThreshold) {
                        obj["batchThreshold"] = this.batchThreshold;
                    }
                    if (!!this.batchCount) {
                        obj["batchCount"] = this.batchCount;
                    }
                    if (!!this.batchEmail) {
                        obj["batchEmail"] = this.batchEmail;
                    }
                    if (!!this.batchTelegram) {
                        obj["batchTelegram"] = this.batchTelegram;
                    }
                    return obj;
                });
                data.paramList = list;
                this.updateConfigDataAction(data);
            } else {
                this.snackBarDanger(
                    "Please provide all input fields to complete update",
                    "Warning",
                    3000
                );
            }
        },
    },
};
</script>
